import styled from 'styled-components';
import { DESKTOP_WIDTH, TABLET_WIDTH } from '../../globalUtils';
import { GrayContainer, Area, Title, RedStripe } from '../index.style';

export { GrayContainer, Area, Title, RedStripe };

export const Text = styled.div`
    color: #003a70;
    font-size: 22px;
    margin-bottom: 72px;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        font-size: 18px;
        margin-bottom: 47px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        font-size: 16px;
        text-align: center;
    }
`;

export const ContactContainer = styled.div`
    display: flex;
    flex-direction: row;
    border-radius: 100px 40px 0 0;
    overflow: hidden;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        border-radius: 70px 28px 0 0;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        flex-direction: column;
        border-radius: 50px 14px 0 0;
    }
`;
