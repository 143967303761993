import React, { useState } from 'react';
import Slider from 'react-slick';
import { useQuery } from '@apollo/react-hooks';

import { ARTICLES } from '../../../graphql';
import { ANIMATION_SPEED } from '../../globalUtils';
import {
    GrayContainer, Area, Title, RedStripe, Arrow, SliderContainer
} from './index.style';

import Article from './Article';

const NextArrow = ({ className, onClick }) => {
    return (
        <Arrow
            className={className}
            onClick={onClick}
            style={{ transform: 'rotate(180deg)' }}
        >></Arrow>
    );
}
const PrevArrow = ({ className, onClick }) => {
    return (
        <Arrow
            className={className}
            onClick={onClick}
            style={{ transform: 'rotate(0deg)' }}
        >{'<'}</Arrow>
    );
}

const Articles = () => {
    const { loading, error, data } = useQuery(ARTICLES);
    const [active, setActive] = useState(1);
    if (loading) {
        return 'loading';
    }
    if (error) {
        return 'error';
    }
    const { articles } = data;
    
    const items = [...articles, ...articles]
        .map(({ id, image, url, publisher, text }, i) => <Article
            key={`ct_${id}`}
            image={image}
            url={url}
            publisher={publisher}
            text={text}
            active={i === active}
        />);
    const settings = {
        infinite: true,
        speed: ANIMATION_SPEED,
        slidesToShow: 3,
        slidesToScroll: 1,
        beforeChange: (_current, next) => {
            const { length } = items;
            if (next + 1 === length) {
                setActive(0);
            } else {
                setActive(next + 1);
            }
        },
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };
    return <GrayContainer>
        <Area>
            <Title>
                <RedStripe />
                סיקרו אותנו בתקשורת
            </Title>
            <SliderContainer>
                <Slider {...settings}>{
                    items
                }</Slider>
            </SliderContainer>
        </Area>
    </GrayContainer>;
};

export default Articles;