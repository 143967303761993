import React from 'react';
import { Container, Loading, LoadingPart } from './index.style';

const Loader = () => (<Container>
    <Loading>
        <LoadingPart />
        <LoadingPart />
        <LoadingPart />
        <LoadingPart />
    </Loading>
</Container>);

export default Loader;