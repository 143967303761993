import React from 'react';
import {
    ArticleContainer, Image, Text, Publisher, ArticleLink, LinkIcon, LinkCTA
} from './Article.style';
const snap = navigator.userAgent !== 'ReactSnap';
const Article = ({ image, url, publisher, text }) => (<ArticleContainer>
    {!!snap && <Image src={image} alt={`Article image by ${publisher}`} />}
    <Text>
        <Publisher>{publisher}</Publisher>
        {text}
    </Text>
    <ArticleLink
        target='_blank'
        href={url}
    >
        <LinkIcon />
        <LinkCTA>מעבר לכתבה</LinkCTA>
    </ArticleLink>
</ArticleContainer>);

export default Article;