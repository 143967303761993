import React, { useState } from 'react';
import Slider from 'react-slick';
import { useQuery } from '@apollo/react-hooks';

import { CLIENT_TESTIMONIES, CONTENT_BY_PAGE } from '../../../graphql';
import { reduceContent, ANIMATION_SPEED } from '../../globalUtils';
import {
    Area, Title, RedStripe, Text, Arrow, SliderContainer, CTA, CTAText, CTAButton,
} from './index.style';

import Testimony from './Testimony';

const NextArrow = ({ className, onClick }) => {
    return (
        <Arrow
            className={className}
            onClick={onClick}
            style={{ transform: 'rotate(180deg)' }}
        ></Arrow>
    );
}
const PrevArrow = ({ className, onClick }) => {
    return (
        <Arrow
            className={className}
            onClick={onClick}
            style={{ transform: 'rotate(0deg)' }}
        >{'<'}</Arrow>
    );
}

const ClientTestimonies = ({ ctaTo, ctaBtnTitle, ctaBtnLink }) => {
    const { loading: l1, error: e1, data: d1 } = useQuery(CONTENT_BY_PAGE, {
        variables: {
            page: 'home',
            language: 'he',
        },
    });
    const { loading: l2, error: e2, data: d2 } = useQuery(CLIENT_TESTIMONIES);
    const [active, setActive] = useState(1);
    if (l1 || l2) {
        return 'loading';
    }
    if (e1 || e2) {
        return 'error';
    }
    const { contentByPage } = d1;
    const { clientTestimonies } = d2;

    const { clients: { cta, ctaText, text } } = reduceContent(contentByPage);
    const items = clientTestimonies.map(({ id, image, name, text }, i) => <Testimony
        key={`ct_${id}`}
        image={image}
        name={name}
        text={text}
        active={i === active}
    />);
    const settings = {
        infinite: true,
        speed: ANIMATION_SPEED,
        slidesToShow: 3,
        slidesToScroll: 1,
        beforeChange: (_current, next) => {
            const { length } = items;
            if (next + 1 === length) {
                setActive(0);
            } else {
                setActive(next + 1);
            }
        },
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };
    return <Area>
        <Title>
            <RedStripe />
            לקוחות מספרים
        </Title>
        {!!text && <Text>{text.text}</Text>}
        <SliderContainer>
            <Slider {...settings}>{
                items
            }</Slider>
        </SliderContainer>
        <CTA>
            {!!ctaText && <CTAText>{ctaText.text}</CTAText>}
            {!!cta && <CTAButton href={ctaBtnLink?.text} target='_new'>{cta.text}</CTAButton>}
            {/* {!!cta && <CTAButton to={ctaTo}>{cta.text}</CTAButton>} */}
        </CTA>
    </Area>;
};

export default ClientTestimonies;