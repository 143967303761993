import React from 'react';
import { Container, Holder, Tab } from './index.style';

const renderTab = (tab, currentTab, setTab, tabNames) => (
    <Tab
        key={`t-${tab}`}
        current={tab === currentTab}
        onClick={() => setTab(tab)}
    >
        {tabNames[tab]}
    </Tab>
);

const Tabs = ({ tabs, tab, setTab, tabNames }) => (
    <Container>
        <Holder>
            {tabs.map(t => renderTab(t, tab, setTab, tabNames))}
        </Holder>
    </Container>
);

export default Tabs;