import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { DESKTOP_WIDTH, TABLET_WIDTH, MOBILE_WIDTH } from '../../globalUtils';
import { ReactComponent as PhoneSVG } from '../../../icons/Phone.svg';
import { ReactComponent as WhatsappSVG } from '../../../icons/Whatsapp.svg';
import { ReactComponent as FacebookSVG } from '../../../icons/Facebook.svg';
import { ReactComponent as BotSVG } from '../../../icons/Bot.svg';
import { ReactComponent as PinSVG } from '../../../icons/Pin.svg';

export const Container = styled.div`
    padding: 30px 78px 10px 48px;
    width: 311px;
    background-color: #ffffff;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        padding: 23px 50px 10px 28px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        width: 292px;
        padding: 19px 14px;
    }
    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        width: 258px;
        padding: 19px 13px;
    }
`;

export const Title = styled.div`
    color: #003a70;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 15px;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        font-size: 20px;
        margin-bottom: 10px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        text-align: center;
    }
`;

export const SubTitle = styled.div`
    color: #ff4438;
    font-size: 22px;
    margin-bottom: 17px;
    
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        font-size: 15px;
        margin-bottom: 12px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        text-align: center;
    }
`;

export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        text-align: column;
    }
`;

export const PhoneLink = styled.a`
    color: #003a70;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 65px;
    display: flex;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        font-size: 20px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        flex-direction: column;
        align-items: center;
        width: 100%;
        text-align: center;
        margin-bottom: 42px;
    }
`;

export const PhoneIcon = styled(PhoneSVG)`
    height: 21px;
    width: 22px;
    margin: 9px 0 0 14px;
    fill: #003a70;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        height: 14px;
        width: 15px;
        margin: 5px 0 0 9px;
    }
`;

export const Link = styled.a`
    color: #003a70;
    font-size: 22px;  
    font-weight: 600;
    margin-bottom: 23px;
    display: flex;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
          font-size: 15px;
          margin-bottom: 16px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        flex-direction: column;
        align-items: center;
        width: 100%;
        text-align: center;
    }
`;
export const InnerLink = styled(RouterLink)`
    color: #003a70;
    font-size: 22px;  
    font-weight: 600;
    margin-bottom: 23px;
    display: flex;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
          font-size: 15px;
          margin-bottom: 16px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        flex-direction: column;
        align-items: center;
        width: 100%;
        text-align: center;
    }
`;

export const LinkText = styled.p`
    width: 260px;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        width: 180px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        margin-top: 5px;
    }
`;

export const WhatsappIcon = styled(WhatsappSVG)`
    height: 26px;
    width: 28px;
    margin: 0 0 -4px 14px;
    fill: #003A70;
    
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        height: 18px;
        width: 20px;
        margin: 0 0 -2px 9px;
    }
`;

export const FacebookIcon = styled(FacebookSVG)`
    height: 26px;
    width: 26px;
    margin: 0 0 -5px 14px;
    fill: #003A70;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        height: 18px;
        width: 18px;
        margin: 0 0 -2px 9px;
    }
`;

export const BotIcon = styled(BotSVG)`
    height: 32px;
    width: 31px;
    margin: 0 -3px -8px 11px;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        height: 22px;
        width: 21px;
        margin: 0 -3px -4px 7px;
    }
`;

export const PinIcon = styled(PinSVG)`
    height: 29px;
    width: 20px;
    margin: 0 0 0 14px;
    
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        height: 20px;
        width: 14px;
        margin: 0 0 0 9px;
    }
`;