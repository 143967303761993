import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html, body, div, span, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  abbr, address, cite, code,
  del, dfn, em, img, ins, kbd, q, samp,
  small, strong, sub, sup, var,
  b, i,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
  }
  html, body, #root {
    width: 100%;
    height: 100%;
  }
  body {
    font-family: 'Assistant', sans-serif;
    font-display: auto;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    background-color: #ffffff;
    direction: rtl;
  }
  body * { 
    white-space: pre-line; 
  }
  input, textarea {
    font-family: 'Assistant', sans-serif;
  }
  svg {
    fill: currentColor;
  }
`;

export default GlobalStyle;