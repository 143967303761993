import React from 'react';

import {
    Container, Title, SubTitle, FlexRow, PhoneLink, PhoneIcon, Link, InnerLink,
    LinkText, WhatsappIcon, FacebookIcon, BotIcon, PinIcon
} from './ContactWays.style'

const ContactWays = ({ contactUsWays: {
    address, bot, facebook, phone, title, titleAddress, titleMoreChannels,
    titlePhone, whatsapp }
}) => (<Container>
    {!!title && <Title>{title.text}</Title>}
    {!!titlePhone && <SubTitle>{titlePhone.text}</SubTitle>}
    {!!phone && <FlexRow>
        <PhoneLink
            target='_blank'
            href={`tel:${phone.text}`}
        ><PhoneIcon /><LinkText>{phone.text}</LinkText></PhoneLink>
    </FlexRow>}
    {!!titleMoreChannels && <SubTitle>{titleMoreChannels.text}</SubTitle>}
    {!!whatsapp && <FlexRow>
        <Link
            target='_blank'
            href='https://api.whatsapp.com/send?phone=972548300920'
        ><WhatsappIcon /><LinkText>{whatsapp.text}</LinkText></Link>
    </FlexRow>}
    {!!facebook && <FlexRow>
        <Link
            target='_blank'
            href='https://www.facebook.com/marvihim/'
        ><FacebookIcon /><LinkText>{facebook.text}</LinkText></Link>
    </FlexRow>}
    {!!bot && <FlexRow>
        <InnerLink
            to='/chatbot/taxrefund'
            style={{ marginBottom: '50px' }}
        ><BotIcon /><LinkText>{bot.text}</LinkText></InnerLink>
    </FlexRow>}
    {!!titleAddress && <SubTitle>{titleAddress.text}</SubTitle>}
    {!!address && <FlexRow>
        <Link
            target='_blank'
            href='https://goo.gl/maps/j1SCD61GgzJVsQcu8'
        ><PinIcon /><LinkText>{address.text}</LinkText></Link>
    </FlexRow>}
</Container>);

export default ContactWays;