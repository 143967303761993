import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoSVG } from '../../../icons/Logo.svg';
import { ReactComponent as LoginSVG } from '../../../icons/Login.svg';
import { ReactComponent as WhatsappSVG } from '../../../icons/Whatsapp.svg';
import { ReactComponent as FacebookSVG } from '../../../icons/Facebook.svg';
import { DESKTOP_WIDTH, TABLET_WIDTH, MOBILE_WIDTH, MOBILE_S_WIDTH } from '../../globalUtils'

export const FooterHolder = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
`;

export const FooterLinks = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 103px;
    background-color: #003a70;
    padding: 0 calc(50% - (${DESKTOP_WIDTH}px / 2));
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        padding: 0 calc(50% - (${TABLET_WIDTH}px / 2));
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        padding: 0 calc(50% - (${MOBILE_WIDTH}px / 2));
        flex-direction: column;
        height: auto;
    }
    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        padding: 0 calc(50% - (${MOBILE_S_WIDTH}px / 2));
    }
`;

export const FooterLinksHolder = styled.div`
    display: flex;
    flex-direction: row;
    
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        border-bottom: 1px solid rgba(202, 207, 214,0.079);

        :last-of-type {
            border-bottom: 0;
        }
    }
`;

export const FooterLink = styled(Link)`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #cacfd6;
    font-size: 16px;
    text-decoration: none;
    margin: 0 21px;

    :first-of-type {
        margin-right: 0;
    }
    :last-of-type {
        margin-left: 0;
    }
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        font-size: 14px;
        margin: 0 10px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        font-size: 13px;
        margin: 22px 15px 23px;
    }
`;

export const FooterLinkHref = styled.a`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #cacfd6;
    font-size: 16px;
    text-decoration: none;
    margin: 0 21px;

    :first-of-type {
        margin-right: 0;
    }
    :last-of-type {
        margin-left: 0;
    }
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        font-size: 14px;
        margin: 0 10px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        font-size: 13px;
        margin: 22px 15px 23px;
    }
`;

export const FooterLinkLogin = styled(Link)`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #cacfd6;
    font-size: 16px;
    text-decoration: none;
    margin: 0 21px;

    :first-of-type {
        margin-right: 0;
    }
    :last-of-type {
        margin-left: 0;
    }
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        font-size: 14px;
        margin: 0 10px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        display: none;
    }
`;

export const FooterLinkLogin2 = styled(Link)`
    display: none;
    flex-direction: row;
    align-items: center;
    color: #cacfd6;
    text-decoration: none;
    font-size: 13px;
    margin: 25px 0 21px 135px;

    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        display: flex;

    }
`;
export const LoginIcon = styled(LoginSVG)`
    height: 18px;
    width: 18px;
    margin-left: 4px;
    fill: #ff4438;
`;

export const FooterIconLink = styled.a`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 5px;
    text-decoration: none;

    :first-of-type {
        margin-right: 0;
    }
    :last-of-type {
        margin-left: 0;
    }
`;

export const FacebookIcon = styled(FacebookSVG)`
    height: 23px;
    width: 23px;
    fill: #cacfd6;
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        height: 19px;
        width: 19px;
    }
`;

export const WhatsappIcon = styled(WhatsappSVG)`
    height: 23px;
    width: 25px;
    fill: #cacfd6;
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        height: 19px;
        width: 20px;
    }
`;

export const FooterRights = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    background-color: #e9ecf1;
    padding: 0 calc(50% - (${DESKTOP_WIDTH}px / 2));
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        padding: 0 calc(50% - (${TABLET_WIDTH}px / 2));
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        padding: 0 calc(50% - (${MOBILE_WIDTH}px / 2));
    }
    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        padding: 0 calc(50% - (${MOBILE_S_WIDTH}px / 2));
    }
`; 

export const RightsText = styled.div`
    color: #ff4438;
    font-size: 15px;
    margin-left: 21px;
`; 

export const MarLogo = styled(LogoSVG)`
    height: 23px;
    width: 88px;
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        height: 19px;
        width: 70px;
    }
`; 

export * from './index.style';