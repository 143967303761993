import styled, { keyframes } from 'styled-components';
import { ANIMATION_SPEED } from '../../globalUtils';
const ANIMATION_SPEED_DELAY = ANIMATION_SPEED * 0.2;

const opacity = keyframes`
    from {
        opacity: 0.25;
    }
    to {
        opacity: 1;
    }
`;

export const Container = styled.div`
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;
`;

export const Loading = styled.div`
    display: flex;
    justify-content: space-around;
    height: 16px;
    width: 90px;
`;

export const LoadingPart = styled.div`
    animation: ${opacity} ${ANIMATION_SPEED}ms cubic-bezier(0.5, 0, 0.5, 1) infinite;
    :after {
        content: " ";
        display: block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #fe483c;
    }
    :nth-child(1) {
        animation-delay: ${ANIMATION_SPEED_DELAY}ms;
    }
    :nth-child(2) {
        animation-delay: ${ANIMATION_SPEED_DELAY * 2}ms;
    }
    :nth-child(3) {
        animation-delay: ${ANIMATION_SPEED_DELAY * 3}ms;
    }
    :nth-child(4) {
        animation-delay: ${ANIMATION_SPEED_DELAY * 4}ms;
    }
`;