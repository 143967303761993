import styled from 'styled-components';
import { DESKTOP_WIDTH, TABLET_WIDTH, MOBILE_WIDTH, ANIMATION_SPEED } from '../../globalUtils';
import { ReactComponent as HeartSVG } from '../../../icons/Heart.svg';
import { ReactComponent as TestimonyBGSVG } from '../../../icons/TestimonyBG.svg';

export const TestimonyContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    transform: ${({ active }) => active ? 'scale(1)' : 'scale(0.7)'};
    transition: transform ${ANIMATION_SPEED}ms ease-in-out;
    margin-top: 100px;
    height: 298px;
    width: 363px;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        height: 212px;
        width: 258px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        height: 180px;
        width: 217px;
    }
    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        height: 172px;
        width: 203px;
        transform: ${({ active }) => active ? 'scale(1)' : 'scale(0.5)'};
    }
`;

export const HeartIcon = styled(HeartSVG)`
    position: absolute;
    top: -87px;
    right: 112px;
    height: 62px;
    width: 59px;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        height: 44px;
        width: 41px;
        top: -71px;
        right: 80px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        height: 37px;
        width: 35px;
        right: 70px;
        top: -64px;
    }
`;

export const TestimonyBG = styled(TestimonyBGSVG)`
    position: absolute;
    height: 298px;
    width: 363px;
    z-index: -1;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        height: 212px;
        width: 258px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        height: 180px;
        width: 217px;
    }
    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        height: 172px;
        width: 203px;
    }
`;

export const Image = styled.img`
    position: absolute;
    border-radius: 50%;
    right: calc(50% - 52px);
    top: -30px;
    height: 104px;
    width: 104px;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        height: 74px;
        width: 74px;
        right: calc(50% - 37px);
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        height: 62px;
        width: 62px;
        right: calc(50% - 31px);
    }
`

export const Name = styled.div`
    color: #ffffff;
    font-size: 36px;
    margin: 71px 0 20px;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        font-size: 25px;
        margin: 50px 0 14px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        font-size: 21px;
        margin: 35px 0 10px;
    }
`;

export const Text = styled.div`
    color: #ffffff;
    font-size: 19px;
    padding: 0 30px 0 15px;
    direction: rtl;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        font-size: 14px;
        padding: 0 21px 0 10px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        font-size: 12px;
    }
`; 
