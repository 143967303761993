import styled from 'styled-components';
import { DESKTOP_WIDTH, TABLET_WIDTH, MOBILE_WIDTH,MOBILE_S_WIDTH } from '../globalUtils';

export const GrayContainer = styled.div`
    display: flex;
    justify-content: center;
    background-color: #e9ecf1;
    width: 100%;
    padding-bottom: 136px;

    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        padding-bottom: 55px;
    }
`;

export const Area = styled.div`
    width: ${DESKTOP_WIDTH}px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        width: ${TABLET_WIDTH}px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        width: ${({ fixedWidth }) => fixedWidth ? `${MOBILE_WIDTH}px` : '100%'};
        overflow: hidden;
    }
    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        width: ${({ fixedWidth }) => fixedWidth ? `${MOBILE_S_WIDTH}px` : '100%'};
        overflow: hidden;
    }
`;

export const Title = styled.div`
    position: relative;
    color: #003a70;
    font-size: 48px;
    font-weight: 600;
    margin: 87px 0 37px;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        font-size: 33px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        font-size: 24px;
        align-self: center;
        margin: 38px 0 12px;
    }
`;

export const RedStripe = styled.div`
    position: absolute;
    top: 0;
    right: 2px;
    height: 9px;
    width: 25px;
    background-color: #ff4438;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        height: 6px;
        width: 18px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        height: 4px;
        width: 13px;
    }
`;