import styled from 'styled-components';

import { DESKTOP_WIDTH, TABLET_WIDTH, MOBILE_WIDTH, MOBILE_S_WIDTH, ANIMATION_SPEED } from '../../globalUtils';
import { ReactComponent as ArrowSolidSVG } from '../../../icons/ArrowSolid.svg'
import { ReactComponent as HeartSvg } from '../../../icons/Heart.svg';
import { ReactComponent as HeartFillSvg } from '../../../icons/HeartFill.svg';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    background-color: #e9ecf1;
    width: 100%;
    padding: 46px 0 127px;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        padding: 31px 0 69px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        padding: 21px 0 47px;
    }
`;

export const Area = styled.div`
    width: ${DESKTOP_WIDTH}px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        width: ${TABLET_WIDTH}px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        width: ${MOBILE_WIDTH}px;
    }
    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        width: ${MOBILE_S_WIDTH}px;
    }
`;

export const Top = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 32px 0 16px;

    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        flex-direction: column;
    }
`;

export const TopText = styled.div`
    color: #003a70;
    font-size: 22px;
    
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        font-size: 16px;
        margin-bottom: 17px;
    }
    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        font-size: 14px;
    }
`;

export const AddQuestionBtn = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 47px;
    width: 257px;
    background-color: #ff4438;
    color: #f1efef;
    font-size: 23px;
    border-radius: 6px;
    box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.5);
    
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        font-size: 20px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        font-size: 16px;
        height: 32px;
        width: 100%;
    }
    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        font-size: 14px;
    }
`;

export const QnAContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    margin:  14px 0;
    border-radius: 20px 5px 0 0;
    background-color: #ffffff;
`;

export const ArrowSolid = styled(ArrowSolidSVG)`
    position: absolute;
    height: 12px;
    width: 11px;
    fill: #ff4438;
    top: 36px;
    left: 32px;
    transition: ${ANIMATION_SPEED}ms transform ease-in-out;
    transform: rotateZ(${({ selected }) => selected ? 0 : 95}deg);

    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        top: 17px;
        left: 18px;
    }
`;

export const Question = styled.div`
    transition: ${ANIMATION_SPEED}ms color ease-in-out;
    color: #${({ selected }) => selected ? 'ff4438' : '003a70'}; 
    font-size: 20px;
    margin: 24px 15px 24px 55px;
    
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        font-size: 18px;
    }
    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        font-size: 16px;
    }
`;

export const Answer = styled.div`
    overflow: hidden;
    height: auto;
    color: #003a70;
    font-size: 18px;
    margin: ${({ selected }) => selected ? '0 15px 24px 55px' : '0 15px 0 55px'};
    max-height:  ${({ selected }) => selected ? '1000px' : '0'};
    transition: ${ANIMATION_SPEED}ms max-height ease-in-out, 
                ${ANIMATION_SPEED}ms margin ease-in-out;

    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        font-size: 16px;
    }
    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        font-size: 14px;
    }
`;

export const AddQuestion = styled.div`
    display: flex;
    flex-direction: row;
    height: 672px;
    margin-top: 61px;
    border-radius: 40px 0 0 0;
    overflow: hidden;
    
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        height: 475px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        flex-direction: column;
        height: auto;
    }
`;

export const QuestionInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 437px;
    background-color: #ffffff;
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        width: 300px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        width: auto;
    }
`;

export const HeartIcon = styled(HeartSvg)`
    height: 74px;
    width: 72px;
    margin: 187px 0 21px;
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        height: 59px;
        width: 58px;
        margin: 84px 0 33px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        height: 42px;
        width: 42px;
        margin: 35px 0 18px;
    }
    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        height: 42px;
        width: 38px;
        margin: 25px 0 18px;
    }
`; 

export const QIBlueText = styled.div`
    color: #003a70;
    text-align: center;
    font-size: 22px;
    width: 304px;
    margin-bottom: 38px;
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        font-size: 18px;
        margin-bottom: 26px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        font-size: 16px;
        width: 259px;
        margin-bottom: 17px;
    }
    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        font-size: 16px;
        width: 241px;
    }
`;

export const QIRedText = styled.div`
    color: #ff4438;
    text-align: center;
    font-size: 22px;
    width: 304px;
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        font-size: 18px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        font-size: 16px;
        width: 133px;
        margin-bottom: 25px;
    }
    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        width: 118px;
    }
`;

export const QuestionForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 404px;
    background-color: #003a70;
    padding: 123px 94px 0 89px;
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        padding: 85px 65px 0 62px;
        width: 279px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        padding: 32px 30px 0 31px;
        width: auto;
    }
    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        padding: 32px 28px 0;
    }
`;

export const Names = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const TextInput = styled.input.attrs(() => ({
    type: 'text',
}))`
    height: 18px;
    background-color: #f9fbff;
    border: 2px solid transparent;
    transition: ${ANIMATION_SPEED}ms all ease-in-out;
    margin-bottom: 18px;
    outline: 0;
    color: #003a70;
    font-size: 18px;
    padding: 13px 11px;

    ::placeholder {
        color: #003a70;
    }
    :focus {
        border: 2px solid rgb(255, 68, 56);
    }

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        padding: 9px 11px;
        font-size: 13px;
        margin-bottom: 10px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        padding: 8px 9px;
    }
    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        font-size: 12px;
    }
`;

export const NarrowTextInput = styled(TextInput)`
    width: 168px;
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        width: 108px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        width: 102px;
    }
    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        width: 88px;
    }
`;

export const WideTextInput = styled(TextInput)`
    width: calc(100% - 26px);
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        width: calc(100% - 22px);
    }
`;

export const TextArea = styled.textarea`
    height: 87px;
    background-color: #f9fbff;
    border: 2px solid transparent;
    transition: ${ANIMATION_SPEED}ms transform ease-in-out;
    margin: 68px 0 16px;
    outline: 0;
    color: #003a70;
    font-size: 18px;
    padding: 13px 11px;
    width: calc(100% - 26px);
    resize: none;

    ::placeholder {
        color: #003a70;
    }
    :focus {
        border: 2px solid rgb(255, 68, 56);
    }
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        margin: 41px 0 13px;
        padding: 9px 11px;
        font-size: 13px;
        height: 56px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        margin: 1px 0 13px;
        padding: 8px 9px;
        width: calc(100% - 22px);
    }
    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        font-size: 12px;
    }
`;

export const Error = styled.div`
    color: #ff4438;
    text-align: center;
    font-size: 18px;
    height: 23px;
    margin-bottom: 21px;
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        font-size: 12px;
        font-weight: 600;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        margin-bottom: 16px;
    }
`; 

export const FormButton = styled.button.attrs(()=>({
    type: 'submit'
}))`
    height: 52px;
    width: 404px;
    background-color: #ff4438;
    border-radius: 6px;
    box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.5);
    color: #f1efef;
    text-align: center;
    font-size: 23px;
    border: 0;
    margin-bottom: 16px;
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        height: 36px;
        width: 279px;
        border-radius: 4px;
        box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.5);
        font-size: 20px;
        margin-bottom: 9px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        height: 33px;
        width: 258px;
        border-radius: 3.84px;
        font-size: 14px;
    }
    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        width: 228px;
    }
`;

export const Cancel = styled.div`
    cursor: pointer;
    color: #f9fbff;
    text-align: center;
    font-size: 18px;
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        font-size: 16px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        font-size: 14px;
        margin-bottom: 20px;
    }
`;

export const Thanks = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    height: 392px;
    width: 592px;
    background-color: #003a70;
    border-radius: 2px;
    margin-top: 156px;
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        margin-top: 94px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        margin-top: 66px;
        height: 392px;
        width: 320px;
    }
    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        width: 284px;
    }
`;

export const HeartFillIcon = styled(HeartFillSvg)`
    height: 38px;
    width: 40px;
    margin: 41px 0 10px;
`;

export const ThanksTitle = styled.div`
    color: #faf9fa;
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 44px;
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        font-size: 28px;
    }
    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        margin-bottom: 26px;
    }
`; 

export const ThanksText = styled.div`
    color: #ffffff;
    text-align: center;
    font-size: 18px;
    width: 378px;
    margin-bottom: 54px;
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        width: auto;
    }
    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        margin-bottom: 50px;
    }
`; 

export const ThanksButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 38px;
    width: 159px;
    background-color: #ff4438;
    border-radius: 7px;
    box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.5);
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
`; 