import React from 'react';
import { Area, Top, TopText, AddQuestionBtn } from './index.style';
import { QnA } from './QnA';

export const Questions = ({ content, selected, setSelected, setAsking, categoryFaqs }) => {
    const { addQuestion, addQuestionText } = content;
    
    return (<Area>
        <Top>
            <TopText>{!!addQuestionText && addQuestionText.text}</TopText>
            <AddQuestionBtn onClick={() => setAsking(true)}>{!!addQuestionText && addQuestion.text}</AddQuestionBtn>
        </Top>
        {categoryFaqs
            .sort(({ position: p1 }, { position: p2 }) => p1 - p2)
            .map(({ id, question, answer }, i) => <QnA key={`qna-${id}`} i={i} question={question} answer={answer} selected={selected === i} setSelected={setSelected} />)}
    </Area>);
};
