import React, {useEffect} from 'react';
import { useGlobal } from 'reactn';
import {
    FooterHolder, FooterLinks, FooterLinksHolder, FooterLink, FooterLinkHref, FooterLinkLogin,
    FooterLinkLogin2, LoginIcon, FooterIconLink, FacebookIcon, WhatsappIcon,
    FooterRights, RightsText, MarLogo
} from './index.style';
import { personalZoneLink } from '../Navigator';
import { useLocation } from 'react-router-dom';

export const Footer = () => {
    const { pathname } = useLocation();
    const [isLogged] = useGlobal('isLogged');
    const botPage = pathname.indexOf('/chatbot') === 0;
    const linkTarget = botPage ? '_blank' : '_self';

    useEffect(() => {
        function updateImageSource() {
            const image = document.getElementById('responsive-image');
            if (window.innerWidth <= 500) {
                image.src = "https://res.cloudinary.com/midrag/image/upload/v1708339656/Trust/logo9.55.png";
                image.style.width = "100px";
            } else {
                image.src = "https://res.cloudinary.com/midrag/image/upload/v1708339657/Trust/widget9.55.png";
                image.style.width = "200px";
            }
        }

        window.addEventListener('resize', updateImageSource);
        updateImageSource();

        return () => {
            window.removeEventListener('resize', updateImageSource);
        };
    }, []);

    return (
        <>
            <div style={{ position: 'fixed', left: '10px', bottom: '10px', zIndex: 100 }}>
                <a href="https://biz.midrag.co.il/Content/LandingPage/19915" target="_blank" rel="noopener noreferrer">
                    <img id="responsive-image" src="https://lp.landing-page.mobi/files/GDU0I/s07akt4d98.png" alt="Midrag Trust" style={{ width: '100px', height: 'auto' }} />
                </a>
            </div>
            <FooterHolder>
                <FooterLinks>
                    <FooterLinksHolder>
                        <FooterLink to='/taxes' target={linkTarget}>החזרי מס</FooterLink>
                        <FooterLink to='/insurance' target={linkTarget}>ביטוח</FooterLink>
                        <FooterLink to='/faq' target={linkTarget}>שאלות ותשובות</FooterLink>
                        <FooterLink to='/#contact' target={linkTarget}>צרו קשר</FooterLink>
                        <FooterLink to='/terms' target={linkTarget}>תקנון</FooterLink>
                        <FooterLinkHref href='https://yeda.marvihim.co.il/%d7%94%d7%a6%d7%94%d7%a8%d7%aa-%d7%a0%d7%92%d7%99%d7%a9%d7%95%d7%aa/' target='_blank'>הצהרת נגישות</FooterLinkHref>
                        <FooterLinkLogin to={personalZoneLink(isLogged)} target={linkTarget}>
                            <LoginIcon />
                            כניסה לאיזור אישי
                        </FooterLinkLogin>
                    </FooterLinksHolder>
                    <FooterLinksHolder>
                        <FooterLinkLogin2 to={personalZoneLink(isLogged)} target={linkTarget}>
                            <LoginIcon />
                            כניסה לאיזור אישי
                        </FooterLinkLogin2>
                        <FooterIconLink
                            target='_blank'
                            href='https://www.facebook.com/marvihim/'
                        >
                            <FacebookIcon />
                        </FooterIconLink>
                        <FooterIconLink
                            target='_blank'
                            href='https://api.whatsapp.com/send?phone=972548300920'
                        >
                            <WhatsappIcon />
                        </FooterIconLink>
                    </FooterLinksHolder>
                </FooterLinks>
                <FooterRights>
                    <RightsText>כל הזכויות שמורות</RightsText>
                    <MarLogo />
                </FooterRights>
            </FooterHolder>
        </>
    );
};
