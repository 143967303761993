import React, { Fragment } from 'react';
import { Links, LoginDesktop, LoginNonDesktop, LoginIcon } from './index.style';
import { Pages } from './Pages';
import { personalZoneLink } from './index';
export const NonPersonalNav = ({ pathname, isLogged }) => (<Fragment>
    <Links>
        <Pages pathname={pathname} />
    </Links>

    <LoginDesktop to={personalZoneLink(isLogged)}>
        <LoginIcon />
        כניסה לאיזור אישי
            </LoginDesktop>
    <LoginNonDesktop to={personalZoneLink(isLogged)}>
        <LoginIcon />
        איזור אישי
            </LoginNonDesktop>
</Fragment>);
