import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { CONTACT } from '../../../graphql';
import {
    Form, Title, Label, FlexRow, TextInput, Textarea, Submit, Error, DoneContainer,
    Heart, DoneTitle, DoneText,
} from './ContactForm.style'

const validateContact = (first, last, phoneNumber, contactMessage, contactUs) => {
    const { phoneEror, missingFields } = contactUs;
    if (first.length < 2) {
        return { type: 'first', text: missingFields.text };
    } else if (last.length < 2) {
        return { type: 'last', text: missingFields.text };
    } else if (phoneNumber.length < 9) {
        return { type: 'phone', text: phoneEror.text };
    } else if (contactMessage.length < 10) {
        return { type: 'message', text: missingFields.text };
    }
};

const Done = ({ contactUs: { doneTitle, doneText } }) => (<DoneContainer>
    <Heart />
    {!!doneTitle && <DoneTitle>{doneTitle.text}</DoneTitle>}
    {!!doneText && <DoneText>{doneText.text}</DoneText>}
</DoneContainer>);

const ContactForm = ({ contactUs }) => {
    const [done, setDone] = useState(false);
    const [error, setError] = useState('');
    const [errorType, setErrorType] = useState('');
    const [first, setFirst] = useState('');
    const [last, setLast] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [contactMessage, setContactMessage] = useState('');
    const [contact] = useMutation(CONTACT);

    const {
        title, titleName, firstName, lastName, titleDetails, email, phone,
        titleMessage, message, submit,
    } = contactUs;

    return done ? <Done
        contactUs={contactUs}
    /> : <Form
        onSubmit={(e) => {
            e.preventDefault();

            const err = validateContact(first, last, phoneNumber, contactMessage, contactUs);

            if (err) {
                setError(err.text);
                setErrorType(err.type);
            } else {
                contact({
                    variables: {
                        firstName: first,
                        lastName: last,
                        email: emailAddress,
                        phone: phoneNumber,
                        message: contactMessage,
                    },
                });

                setFirst('');
                setLast('');
                setEmailAddress('');
                setPhoneNumber('');
                setContactMessage('');
                setDone(true);
            }
        }}
    >
            {!!title && <Title>{title.text}</Title>}
            {!!titleName && <Label>{titleName.text}</Label>}
            <FlexRow>
                {!!firstName && <TextInput
                    placeholder={firstName.text}
                    value={first}
                    error={errorType === 'first'}
                    onChange={({ target: { value } }) => {
                        setFirst(value);
                        setError('');
                        setErrorType('');
                    }}
                />}
                {!!lastName && <TextInput
                    placeholder={lastName.text}
                    value={last}
                    error={errorType === 'last'}
                    onChange={({ target: { value } }) => {
                        setLast(value);
                        setError('');
                        setErrorType('');
                    }}
                />}
            </FlexRow>
            {!!titleDetails && <Label>{titleDetails.text}</Label>}
            {!!email && <TextInput
                placeholder={email.text}
                wide={true}
                value={emailAddress}
                error={errorType === 'email'}
                onChange={({ target: { value } }) => {
                    setEmailAddress(value);
                    setError('');
                    setErrorType('');
                }}
            />}
            {!!phone && <TextInput
                placeholder={phone.text}
                wide={true}
                value={phoneNumber}
                type='number'
                error={errorType === 'phone'}
                onChange={({ target: { value } }) => {
                    setPhoneNumber(value);
                    setError('');
                    setErrorType('');
                }}
            />}
            {!!titleMessage && <Label>{titleMessage.text}</Label>}
            {!!message && <Textarea
                placeholder={message.text}
                value={contactMessage}
                error={errorType === 'message'}
                onChange={({ target: { value } }) => {
                    setContactMessage(value);
                    setError('');
                    setErrorType('');
                }}
            />}
            {!!error && <Error>{error}</Error>}
            {!!submit && <Submit>{submit.text}</Submit>}
        </Form>;
};

export default ContactForm;
