import styled from 'styled-components';
import { DESKTOP_WIDTH, TABLET_WIDTH, MOBILE_WIDTH, ANIMATION_SPEED } from '../../globalUtils';
import { ReactComponent as LinkSVG } from '../../../icons/Link.svg'

export const ArticleContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 324px;
    width: 282px;
    overflow: hidden;
    border-radius: 50px 20px 0 0;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        height: 233px;
        width: 203px;
        border-radius: 35px 14px 0 0;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        height: 218px;
        width: 180px;
    }
    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        height: 218px;
        width: 180px;
    }

`;

export const Image = styled.img` 
    height: 218px;
    object-fit: cover;
    object-position: center;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        height: 157px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        height: 147px;
    }
`;

export const Text = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 106px;
    background-color: #003a70;
    color: #ffffff;
    font-size: 19px;
    padding: 20px;
    direction: rtl;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        font-size: 14px;
        padding: 15px;
        height: 46px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        font-size: 13px;
        height: 41px;
    }
`;

export const Publisher = styled.div`
    position: absolute;
    top: -16px;
    height: 32px;
    line-height: 32px;
    background-color: #ff4438;
    border-radius: 4px;
    color: #ffffff;
    text-align: center;
    font-size: 19px;
    width: 106px;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        height: 23px;
        line-height: 23px;
        width: 76px;
        font-size: 13px;
    }
`;

export const ArticleLink = styled.a`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #003a70;
    opacity: 0;
    transition: ${ANIMATION_SPEED}ms opacity ease-in-out;
    text-decoration: none;
    border-radius: 50px 20px 0 0;
    
    ${ArticleContainer}:hover & {
        opacity: 0.931439;
    }

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        border-radius: 35px 14px 0 0;
    }
`;

export const LinkIcon = styled(LinkSVG)`
    height: 28px;
    width: 28px;
    margin-bottom: 36px;
    fill: #fffffe;
    
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        height: 20px;
        width: 20px;
        margin-bottom: 26px;
    }
`;

export const LinkCTA = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 131px;
    background-color: #ff4438;
    border-radius: 5.28px;
    box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.5);
    color: #ffffff;
    font-size: 19px;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        height: 29px;
        width: 94px;
        border-radius: 3.8px;
        box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.5);
        font-size: 14px;
    }
`; 
