import styled from 'styled-components';

export const Root = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    overflow: ${({ navOpen }) => navOpen ? 'hidden' : 'inherit'};
`;

export const RouterContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;   
    display: flex;
    flex-direction: column; 
    background-color: #ffffff;
    flex: 1 0 auto;
    z-index: 1;
`;

export const NavBlocker = styled.div`
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    z-index: 2;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
`;
