import React from 'react';
import { useGlobal } from 'reactn';
import { useLocation } from 'react-router-dom';
import { SideNavigator, SideNavigatorLinks, LogoLight, LoginMobile, LoginIconMobile, ChatBot, BotLight, StyledLink, LogoutIcon, ArrowRedIcon } from './index.style';
import { personalZoneLink, checkPath } from './index';
import { Pages } from './Pages';
import { useQuery } from '@apollo/react-hooks';
import { CONTENT_BY_PAGE } from '../../../graphql';
import { reduceContent } from '../../globalUtils';
import { LogoutUser } from '../Auth';

export const SideNav = ({ navOpen }) => {
    const [isLogged] = useGlobal('isLogged');
    const { pathname } = useLocation();
    const { loading, error, data } = useQuery(CONTENT_BY_PAGE, {
        variables: {
            page: 'personalZone',
            language: 'he',
        },
    });
    const { data: d1 } = useQuery(CONTENT_BY_PAGE, {
        variables: {
            page: 'whatsapp',
            language: 'he',
        },
    });

    if (loading || error) {
        return <SideNavigator></SideNavigator>;
    }
    const { contentByPage } = data;
    const reducedContent = reduceContent(contentByPage);
    const { navbar: {
        menu: {
            personalInfoEdit,
            signOut,
        },
    } } = reducedContent;
    const inPersonalZone = pathname.indexOf('/personalZone') !== -1;

    const { contentByPage: cpg } = d1 || {};
    const { head :hd1 } = cpg ? reduceContent(cpg) : {};

    const { title: tlt1, mobile, desktop } = hd1 || {};

    console.log(tlt1);
    console.log(mobile);
    console.log(desktop);
    const ua = navigator.userAgent;
    let ctaBtnLink = desktop;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
        ctaBtnLink = mobile;
        console.log(ctaBtnLink);
    } else{
        ctaBtnLink = desktop;
        console.log(ctaBtnLink);
    }

    return (<SideNavigator navOpen={navOpen}>
        <LogoLight />
        {!inPersonalZone && <SideNavigatorLinks>
            <LoginMobile to={personalZoneLink(isLogged)}><LoginIconMobile />לאיזור האישי</LoginMobile>
            <Pages />
            {/* <ChatBot to='/chatbot/taxrefund'>בדיקת הסיכוי להחזר מס מול הצ׳אט-בוט שלנו<BotLight /></ChatBot> */}
            <ChatBot href={ctaBtnLink?.text} target='_new'>בדיקת הסיכוי להחזר מס מול הצ׳אט-בוט שלנו<BotLight /></ChatBot>
        </SideNavigatorLinks>}
        {!!inPersonalZone && <SideNavigatorLinks>
            <StyledLink to='/personalZone#edit' current={checkPath(pathname, '/taxes')}>
                {personalInfoEdit.text}<ArrowRedIcon />
            </StyledLink>

            <StyledLink to='/' onClick={() => LogoutUser()}>{signOut.text}<LogoutIcon /></StyledLink>
        </SideNavigatorLinks>}
    </SideNavigator>);
};
