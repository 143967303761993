import styled from 'styled-components';

import { DESKTOP_WIDTH, TABLET_WIDTH, MOBILE_WIDTH, MOBILE_S_WIDTH, ANIMATION_SPEED } from '../../globalUtils';

export const Container = styled.div`
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    align-items: center;
    justify-content: flex-end;
    height: 132px;
    width: 100%;
    background-color: #003a70;
    
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        height: 98px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        height: 60px;
        border-radius: 50px 5px 0 0;
        background-color: #ffffff;
    }
`;

export const Holder = styled.div`
    height: 88px;
    background-color: #ffffff;
    border-radius: 50px 5px 0 0;
    width: ${DESKTOP_WIDTH}px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        height: 60px;
        width: ${TABLET_WIDTH}px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        width: ${MOBILE_WIDTH}px;
        border-radius: 0;
    }
    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        width: ${MOBILE_S_WIDTH}px;
    }
`;

export const Tab = styled.div`
    color: #003a70;
    cursor: pointer;
    font-size: 27px;
    font-weight: 600;
    display: flex;
    flex: 1 0 auto;
    margin: 0 0.5px;
    justify-content: center;
    align-items: center;
    transition: ${ANIMATION_SPEED}ms box-shadow ease-in-out;
    box-shadow: ${({ current }) => current ? 'inset 0px -6px 0px 0px #ff4438' : 'inset 0px -3px 0px 0px rgba(0, 58, 112, 0.3)'};

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        font-size: 18px;
        font-weight: 400;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        font-size: 16px;
    }
`;
