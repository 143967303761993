import styled from 'styled-components';
import { DESKTOP_WIDTH, TABLET_WIDTH, MOBILE_WIDTH } from '../../globalUtils';
import { ReactComponent as HeartHollowSVG } from '../../../icons/HeartHollow.svg';

const base = `
    display: flex;
    flex-direction: column;
    width: 409px;
    background-color: #003a70;
    padding: 30px 89px;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        width: 279px;
        padding: 23px 64px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        background-color: #425c8a;
        width: 292px;
        padding: 19px 14px;
    }
    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        width: 258px;
        padding: 19px 13px;
    }
`;
export const Form = styled.form`${base}`;

export const Title = styled.div`
    color: #ffffff;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 16px;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        font-size: 20px;
        margin-bottom: 11px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        text-align: center;
    }
`;

export const Label = styled.label`
    color: #ffffff;
    font-size: 22px;
    margin-bottom: 13px;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        font-size: 15px;
        margin-bottom: 7px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        text-align: center;
    }
`;

export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const TextInput = styled.input.attrs({
    type: 'text',
})`
    background-color: #f9fbff;
    padding: 9px 17px 9px 0;
    border: 0;
    color: #003a70;
    font-size: 18px;
    margin-bottom: 16px;
    width: ${({ wide }) => wide ? 392 : 173}px;
    box-shadow: ${({ error }) => error ? 'inset 0px 0px 0px 2px red' : 'none'};
    
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        padding: 9px 9px 9px 0;
        margin-bottom: 10px;
        font-size: 12px;
        width: ${({ wide }) => wide ? 261 : 116}px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        width: ${({ wide }) => wide ? 283 : 131}px;
    }
    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        width: ${({ wide }) => wide ? 249 : 115}px;
    }
`;

export const Textarea = styled.textarea`
    background-color: #f9fbff;
    padding: 9px 17px 9px 0;
    border: 0;
    color: #003a70;
    font-size: 18px;
    margin-bottom: 16px;
    width: 392px;
    height: 93px;
    resize: none;
    box-shadow: ${({ error }) => error ? 'inset 0px 0px 0px 2px red' : 'none'};

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        padding: 9px 9px 9px 0;
        margin-bottom: 10px;
        font-size: 12px;
        width: 261px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        width: 283px;
    }
    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        width: 249px;
    }
`;

export const Submit = styled.button.attrs({
    type: 'submit'
})`
    height: 52px;
    border: 0;
    cursor: pointer;
    background-color: #ff4438;
    border-radius: 6px;
    box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.5);
    color: #f1efef;
    font-size: 24px;
    margin-top: 14px;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        height: 36px;
        border-radius: 4px;
        box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.5);
        font-size: 16px;
    }
`; 

export const Error = styled.div`
    color: #ff4438;
    text-align: center;
    font-size: 18px;
    font-family: Assistant;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        font-size: 12px;
        font-weight: 600;
    }     
`; 

export const DoneContainer = styled.div`
    ${base}
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        padding: 135px 14px 155px;
    }
`;

export const Heart = styled(HeartHollowSVG)`
    height: 42px;
    width: 45px;
    fill: #ff4438;
    margin-bottom: 14px;
    
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        height: 38px;
        width: 40px;
    }
    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        height: 29px;
        width: 31px;
    }
`; 

export const DoneTitle = styled.div`
    color: #ffffff;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 10px;

    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        font-size: 20px;
        font-weight: 400;
    }
`; 

export const DoneText = styled.div`
    color: #ffffff;
    text-align: center;
    font-size: 20px; 
    font-weight: 600;
    width: 275px;
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        font-size: 16px;
        width: 193px;
        font-weight: 400;
    }
`;