import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { NEW_FAQ } from '../../../graphql';
import {
    Area, AddQuestion, QuestionInfo, HeartIcon, QIBlueText, QIRedText,
    QuestionForm, Names, NarrowTextInput, WideTextInput, TextArea, Error,
    FormButton, Cancel, Thanks, HeartFillIcon, ThanksTitle, ThanksText, ThanksButton
} from './index.style';

const validateForm = (first, last, phoneNumber, newQuestion, content) => {
    const { phoneEror, missingFields } = content;
    if (first.length < 2) {
        return { type: 'first', text: missingFields.text };
    } else if (last.length < 2) {
        return { type: 'last', text: missingFields.text };
    } else if (phoneNumber.length < 9) {
        return { type: 'phone', text: phoneEror.text };
    } else if (newQuestion.length < 9) {
        return { type: 'newQuestion', text: missingFields.text };
    }
};

export const NewQuestion = ({ category, content, setAsking }) => {
    const [done, setDone] = useState(false);
    const [error, setError] = useState('');
    const [first, setFirst] = useState('');
    const [last, setLast] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [newQuestion, setNewQuestion] = useState('');
    const [newFaq] = useMutation(NEW_FAQ);
    const {
        text, text2,
        form: {
            cancel, cta, email, firstName, lastName, phone, question
        },
        thanks: {
            back, text: thanksText, title
        }
    } = content;

    return (!done ? <Area>
        <AddQuestion>
            <QuestionInfo>
                <HeartIcon />
                {!!text && <QIBlueText>{text.text}</QIBlueText>}
                {!!text2 && <QIRedText>{text2.text}</QIRedText>}
            </QuestionInfo>
            <QuestionForm onSubmit={(e) => {
                e.preventDefault();
                const err = validateForm(first, last, phoneNumber, newQuestion, content.form);

                if (err) {
                    setError(err.text);
                } else {
                    newFaq({
                        variables: {
                            firstName: first,
                            lastName: last,
                            email: emailAddress,
                            phone: phoneNumber,
                            question: newQuestion,
                            category,
                        },
                    });

                    setFirst('');
                    setLast('');
                    setEmailAddress('');
                    setPhoneNumber('');
                    setNewQuestion('');
                    setDone(true);
                }
            }}>
                <Names>
                    <NarrowTextInput
                        placeholder={firstName.text}
                        value={first}
                        onChange={({ target: { value } }) => {
                            setFirst(value);
                            setError('');
                        }}
                    />
                    <NarrowTextInput
                        placeholder={lastName.text}
                        value={last}
                        onChange={({ target: { value } }) => {
                            setLast(value);
                            setError('');
                        }}
                    />
                </Names>
                <WideTextInput
                    placeholder={email.text}
                    value={emailAddress}
                    onChange={({ target: { value } }) => {
                        setEmailAddress(value);
                        setError('');
                    }}
                />
                <WideTextInput
                    placeholder={phone.text}
                    value={phoneNumber}
                    onChange={({ target: { value } }) => {
                        setPhoneNumber(value);
                        setError('');
                    }}
                />
                <TextArea
                    placeholder={question.text}
                    value={newQuestion}
                    onChange={({ target: { value } }) => {
                        setNewQuestion(value);
                        setError('');
                    }}
                />
                <Error>{error}</Error>
                <FormButton>{cta.text}</FormButton>
                <Cancel onClick={() => setAsking(false)}>{cancel.text}</Cancel>
            </QuestionForm>
        </AddQuestion>
    </Area> : <Area>
            <Thanks>
                <HeartFillIcon />
                <ThanksTitle>{title.text}</ThanksTitle>
                <ThanksText>{thanksText.text}</ThanksText>
                <ThanksButton onClick={() => {
                    setAsking(false);
                    setDone(false);
                }}>{back.text}</ThanksButton>
            </Thanks>
        </Area>);
};
