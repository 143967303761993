import React, { useState, Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { Init } from '../Auth';
import PrivateRoute from './PrivateRoute';
import { Navigator, SideNav } from '../Navigator';
import { Footer } from '../Footer';
import { RouterContainer, Content, Root, NavBlocker } from './index.style';
import { Loader } from '../../Generic';

const Home = lazy(() => import('../../Home'));
const Taxes = lazy(() => import('../../Taxes'));
const Faq = lazy(() => import('../../Faq'));
const Insurance = lazy(() => import('../../Insurance'));
const Bot = lazy(() => import('../../Bot'));
const Login = lazy(() => import('../../Login'));
const PersonalZone = lazy(() => import('../../PersonalZone'));
const Terms = lazy(() => import('../../Terms'));
const TaxRefundQuest = lazy(() => import('../../TaxRefundQuest'));

const AppRouter = () => {
    useEffect(() => {
        Init();
    }, []);
    const [navOpen, setNavOpen] = useState(false);
    const [fullscreen] = useGlobal('fullscreen');

    return <Router>
        <Root navOpen={navOpen}>
            <SideNav navOpen={navOpen} />

            <RouterContainer>
                {navOpen ? <NavBlocker onClick={() => setNavOpen(false)} /> : null}
                {!fullscreen && <Navigator
                    navOpen={navOpen}
                    setNavOpen={setNavOpen}
                />}
                <Content>
                    <Suspense fallback={<Loader/>}>
                        <Switch>
                            <Route path='/' exact component={Home} />
                            <Route path='/taxes' exact component={Taxes} />
                            <Route path='/faq' exact component={Faq} />
                            <Route path='/insurance' exact component={Insurance} />
                            <Route path='/chatbot/:name' exact component={Bot} />
                            <Route path='/login' exact component={Login} />
                            <Route path='/terms' exact component={Terms} />
                            <Route path='/tax-refund-quest' exact component={TaxRefundQuest} />
                            <PrivateRoute path='/personalZone' exact component={PersonalZone} />
                            <Redirect to='/' />
                        </Switch>
                    </Suspense>
                </Content>
                <Footer />
            </RouterContainer>
        </Root>
    </Router>;
};

export default AppRouter;
