import merge from 'deepmerge';
export const DESKTOP_WIDTH = 1024, TABLET_WIDTH = 708, MOBILE_WIDTH = 322,
    MOBILE_S_WIDTH = 286;
export const ANIMATION_SPEED = 840;

export const reduceContent = (content) => {
  // Add a null or undefined check at the beginning
  if (!content || !Array.isArray(content)) {
    console.error('Invalid content:', content);
    return {}; // Return an empty object or handle the error appropriately
  }

  return content.reduce((data, { key, text, image, number }) => {
    const o = key.split('.').reverse().reduce((obj, k, i) => {
      if (i === 0) {
        return { [k]: { text, image, number } };
      }
      return {
        [k]: { ...obj }
      };
    }, {});

    return merge(data, o);
  }, {});
};

// export const reduceContent = (content) => content
//     .reduce((data, { key, text, image, number }) => {
//         const o = key.split('.').reverse().reduce((obj, k, i) => {
//             if (i === 0) {
//                 return { [k]: { text, image, number } };
//             }
//             return {
//                 [k]: { ...obj }
//             };
//         }, {});

//         return merge(data, o);
//     }, {});

export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(String(email).toLowerCase());
}

export const parseSearch = (search) => {
    const [, s] = search.split('?');

    return s ? s.split('&').reduce((parsed, r) => {
        const [key, value] = r.split('=');

        return {
            ...parsed,
            [key]: value,
        };
    }, {}) : {};
};