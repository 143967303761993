import React, { Fragment } from 'react';
import {StyledLink, StyledLinkHref, RedStripe, Arrow} from './index.style';
import { checkPath } from './index';
export const Pages = ({ pathname }) => (<Fragment>
    <StyledLink to='/taxes' current={checkPath(pathname, '/taxes')}>
        <RedStripe />החזרי מס<Arrow />
    </StyledLink>
    <StyledLink to='/insurance' current={checkPath(pathname, '/insurance')}>
        <RedStripe />ביטוח<Arrow />
    </StyledLink>
    <StyledLink to='/faq' current={checkPath(pathname, '/faq')}>
        <RedStripe />שאלות ותשובות<Arrow />
    </StyledLink>
    <StyledLinkHref href="https://yeda.marvihim.co.il" target="_blank">
        <RedStripe />בלוג<Arrow />
    </StyledLinkHref>
    <StyledLink to='/#contact'>
        <RedStripe />צרו קשר<Arrow />
    </StyledLink>
</Fragment>);
