import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { reduceContent } from '../../globalUtils';
import { CONTENT_BY_PAGE, CATEGORY_FAQS } from '../../../graphql';

import {
    Container,
} from './index.style';
import { Questions } from './Questions';
import { NewQuestion } from './NewQuestion';
import Loader from '../Loader';

const Faqs = ({ category }) => {
    const [selected, setSelected] = useState(-1);
    const [asking, setAsking] = useState(false);
    const [lastCategory, setLastCategory] = useState();
    useEffect(() => {
        if (category !== lastCategory) {
            setLastCategory(category);
            setAsking(false);
        }
    }, [category, lastCategory]);
    const { loading: l1, error: e1, data: d1 } = useQuery(CONTENT_BY_PAGE, {
        variables: {
            page: 'faq',
            language: 'he',
        },
    });
    const { loading: l2, error: e2, data: d2 } = useQuery(CATEGORY_FAQS, {
        variables: {
            category
        },
    });

    if (l1 || l2) {
        return <Container>
            <Loader />
        </Container>;
    }
    if (e1 || e2) {
        return <Container>קרתה שגיאה</Container>;
    }

    const { contentByPage } = d1;
    const { categoryFaqs } = d2;
    const reducedContent = reduceContent(contentByPage);
    const { top, addQuestion } = reducedContent;

    return <Container>
        {!asking ?
            <Questions
                content={top}
                selected={selected}
                setSelected={setSelected}
                setAsking={setAsking}
                categoryFaqs={categoryFaqs}
            /> :
            <NewQuestion
                category={category}
                content={addQuestion}
                setAsking={setAsking}
            />}
    </Container>
};

export default Faqs;