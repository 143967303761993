import React, { Fragment, useState } from 'react';
import { useGlobal } from 'reactn';
import { useQuery } from '@apollo/react-hooks';
import { LogoutUser } from '../Auth';
import { reduceContent } from '../../globalUtils';
import { CONTENT_BY_PAGE } from '../../../graphql';
import {
    PersonalLinksDesktop, PersonalLinks, PersonalLink, PersonalDropDown,
    ArrowDownIcon, DropDownMenu, DDMenuAction, ArrowRedIcon, LogoutIcon, PersonalDropDownMobile, SoonIcon,
} from './index.style';
import { useLocation } from 'react-router-dom';

const RenderPersonalLink = ({ currentHash, linkHash, text, soon }) => linkHash ? (<PersonalLink
    current={(currentHash === linkHash).toString()}
    to={`/personalZone${linkHash}`}
>{text}</PersonalLink>) : soon ? <PersonalLink soon>
    <SoonIcon />
    {text}
</PersonalLink> : null;

export const PersonalLinksComp = () => {
    const { hash } = useLocation();
    const { loading, error, data } = useQuery(CONTENT_BY_PAGE, {
        variables: {
            page: 'personalZone',
            language: 'he',
        },
    });
    if (loading || error) {
        return <Fragment></Fragment>;
    }
    const { contentByPage } = data;
    const reducedContent = reduceContent(contentByPage);
    const { navbar: {
        taxTracker,
        myIns,
        mySavings,
        lifeEvents,
        financialDiagnosis,
    } } = reducedContent;

    return (<PersonalLinks>
        <RenderPersonalLink
            currentHash={hash}
            linkHash='#tax'
            text={taxTracker.text}
        />
        <RenderPersonalLink
            currentHash={hash}
            linkHash='#ins'
            text={myIns.text}
        />
        <RenderPersonalLink
            currentHash={hash}
            linkHash='#saving'
            text={mySavings.text}
        />
        <RenderPersonalLink
            currentHash={hash}
            linkHash='#lifeEvents'
            text={lifeEvents.text}
        />
        <RenderPersonalLink
            currentHash={hash}
            // linkHash='#financialDiagnosis'
            text={financialDiagnosis.text}
            soon
        />
    </PersonalLinks>);
};


export const PersonalNav = () => {
    const [user] = useGlobal('user');
    const [menuOpen, setMenuOpen] = useState(false);
    const { loading, error, data } = useQuery(CONTENT_BY_PAGE, {
        variables: {
            page: 'personalZone',
            language: 'he',
        },
    });
    if (loading || error) {
        return <Fragment></Fragment>;
    }
    const { contentByPage } = data;
    const reducedContent = reduceContent(contentByPage);
    const { navbar: {
        hello,
        menu: {
            personalInfoEdit,
            signOut,
        },
    } } = reducedContent;

    return (<Fragment>
        <PersonalLinksDesktop>
            <PersonalLinksComp />
        </PersonalLinksDesktop>
        <PersonalDropDown onClick={() => setMenuOpen(!menuOpen)}>
            {hello.text} {user.name.first} <ArrowDownIcon />
            {!!menuOpen && <DropDownMenu>
                <DDMenuAction to='/personalZone#edit'>{personalInfoEdit.text}<ArrowRedIcon /></DDMenuAction>
                <DDMenuAction to='/' onClick={() => LogoutUser()}>{signOut.text}<LogoutIcon /></DDMenuAction>
            </DropDownMenu>}
        </PersonalDropDown>
        <PersonalDropDownMobile>
            {hello.text} {user.name.first}
        </PersonalDropDownMobile>
    </Fragment>);
};