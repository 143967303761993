import React from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useGlobal } from 'reactn';
import {
    NavHolder, Nav, Logo, LogoLean, Hamburger, HamburgerIndication, 
    PersonalLinksTablet,
} from './index.style';
import { NonPersonalNav } from './NonPersonalNav';
import { PersonalNav, PersonalLinksComp } from './PersonalNav';

export const checkPath = (currentPathname, pathname) => (currentPathname === pathname).toString();

export const personalZoneLink = (isLogged) => isLogged ? '/personalZone' : '/login';;

export const Navigator = ({ navOpen, setNavOpen }) => {
    const { listen } = useHistory();
    const { pathname } = useLocation();
    const [isLogged] = useGlobal('isLogged');
    const inPersonalZone = pathname.indexOf('/personalZone') !== -1;
    listen(() => setNavOpen(false));

    return (<NavHolder>
        <Nav inPersonalZone={inPersonalZone}>
            <Hamburger onClick={() => setNavOpen(!navOpen)} inPersonalZone={inPersonalZone}>
                <HamburgerIndication navOpen={navOpen} />
            </Hamburger>

            <Link to='/'><Logo /><LogoLean /></Link>
            {!inPersonalZone && <NonPersonalNav
                pathname={pathname}
                isLogged={isLogged}
            />}
            {!!inPersonalZone && <PersonalNav />}
        </Nav>
        {!!inPersonalZone && <PersonalLinksTablet>
            <PersonalLinksComp />
        </PersonalLinksTablet>}
    </NavHolder>);
};
export * from './SideNav';