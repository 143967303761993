import styled from 'styled-components';
// import { Link } from 'react-router-dom';
import { DESKTOP_WIDTH, TABLET_WIDTH,MOBILE_WIDTH } from '../../globalUtils';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ReactComponent as ArrowSVG } from '../../../icons/Arrow.svg'

import { GrayContainer, Area, Title, RedStripe } from '../index.style';

export { GrayContainer, Area, Title, RedStripe };

export const Arrow = styled(ArrowSVG)`
    height: 24px!important;
    width: 14px!important;
    fill: #ff4438;
    
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        height: 17px!important;
        width: 10px!important;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        display: none!important;
    }
`;

export const SliderContainer = styled.div`
    width: 100%;

    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        width: 190%;
        margin-right: calc(-60% + 90px);
    }
    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        width: 220%;
        margin-right: calc(-80% + 90px)
    }
`