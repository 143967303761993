import { gql } from 'apollo-boost';

export const CONTACT = gql`
mutation Contact($firstName: String!, $lastName: String!, $email: String!, 
                $phone: String!, $message: String!) {
        contact(firstName: $firstName, lastName: $lastName, email: $email,
                phone: $phone, message: $message)
}`;

export const NEW_FAQ = gql`
mutation NewFaq($firstName: String!, $lastName: String! $email: String,
                $phone: String!, $question: String!, $category: String!) {
        newFaq(firstName: $firstName, lastName: $lastName, email: $email, phone: $phone,
                question: $question, category: $category)
}`;

export const INSURANCE_LEAD = gql`
mutation InsuranceLead($firstName: String!, $lastName: String! $email: String!, 
                $phone: String!) {
        insuranceLead(firstName: $firstName, lastName: $lastName email: $email, 
                phone: $phone)
}`;

export const PRE_LOGIN = gql`
mutation PreLogin($phone: String!, $idNumber: String!) {
        preLogin(phone: $phone, idNumber: $idNumber)
}`;

export const LOGIN = gql`
mutation Login($phone: String!, $idNumber: String!, $code: String!) {
        login(phone: $phone, idNumber: $idNumber, code: $code) {
                providerId
                name {
                        first
                        last
                }
                idNumber
                phone
                email
                status
                address
                taxEstimation
                lifeEventsIds
                accountManager {
                        image
                        name
                        email
                        phone
                }
                taxRefunds {
                        date
                        amount
                }
                insurances {
                        company
                        kind
                        name
                }
                pansions {
                        company
                        kind
                        amount
                }
                token
        }
}`;

export const EDIT_PERSONAL_ZONE = gql`
mutation EditPersonalZone($phone: String!, $email: String!, $address: String!) {
    editPersonalZone(phone: $phone, email: $email, address: $address) {
        providerId
        name {
                first
                last
        }
        idNumber
        phone
        email
        status
        address
        taxEstimation
        lifeEventsIds
        accountManager {
                image
                name
                email
                phone
        }
        taxRefunds {
                date
                amount
        }
        insurances {
                company
                kind
                name
        }
        pansions {
                company
                kind
                amount
        }
    }
}`;

export const UPDATE_LIFE_EVENTS = gql`
mutation UpdateLifeEvents($lifeEventsIds: [ID]!) {
        updateLifeEvents(lifeEventsIds: $lifeEventsIds)
}`;

export const ASK_FOR_INSURANCE = gql`
mutation AskForInsurance($type: String!) {
        askForInsurance(type: $type)
}`;

export const ASK_FOR_SAVING = gql`
mutation AskForSaving($type: String!) {
        askForSaving(type: $type)
}`;

export const TAX_REFUND_QUEST = gql`
mutation TaxRefundQuest($answers: JSON!, $lead: String, $source: String) {
        taxRefundQuest(answers: $answers, lead: $lead, source: $source)
}`;

export const TAX_REFUND_QUEST_ADD_ID = gql`
mutation TaxRefundQuestAddId($plandoId: String!, $idNumber: String!, $idDate: String, $phone: String!, $name: String!) {
        taxRefundQuestAddId(plandoId: $plandoId, idNumber: $idNumber, idDate: $idDate, phone: $phone, name: $name)
}`;

export const QUESTIONNAIRE_DONE = gql`
mutation QuestionnaireDone($answers: JSON!, $questionnaireName: String!) {
        questionnaireDone(answers: $answers, questionnaireName: $questionnaireName)
}`;
