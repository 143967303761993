import styled from 'styled-components';
import { DESKTOP_WIDTH, TABLET_WIDTH, MOBILE_WIDTH,MOBILE_S_WIDTH } from '../../globalUtils';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ReactComponent as ArrowSVG } from '../../../icons/Arrow.svg'

import { Area, Title, RedStripe } from '../index.style';
// import { Link } from 'react-router-dom';

export { Area, Title, RedStripe };

export const Text = styled.div`
    color: #003a70;
    font-size: 22px;
    padding-left: 21px;
    margin-bottom: 23px;
    
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        font-size: 18px;
        padding-left: 0;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        text-align: center;
        font-size: 16px;
        width: ${MOBILE_WIDTH}px;
        align-self: center;
    }
    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        width: ${MOBILE_S_WIDTH}px;
    }
`;

export const Arrow = styled(ArrowSVG)`
    height: 24px!important;
    width: 14px!important;
    fill: #ff4438;
    
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        height: 17px!important;
        width: 10px!important;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        display: none!important;
    }
`;

export const CTA = styled.div`
    margin-top: 46px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const CTAText = styled.div`
    color: #003a70;
    font-size: 22px;
    margin-bottom: 20px;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        font-size: 15px;
    }
`;

// export const CTAButton = styled(Link)`
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     text-decoration: none;
//     height: 62px;
//     width: 320px;
//     background-color: #ff4438;
//     border-radius: 6px;
//     box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.5);
//     color: #f1efef;
//     font-size: 24px;
//     margin-bottom: 118px;

//     @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
//         height: 43px;
//         width: 222px;
//         border-radius: 4px;
//         box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.5);
//         font-size: 16px;
//         margin-bottom: 69px;
//     }
//     @media only screen and (max-width: ${TABLET_WIDTH}px) {
//         width: 320px;
//         margin-bottom: 48px;
//     }
//     @media only screen and (max-width: ${MOBILE_WIDTH}px) {
//         width: 284px;
//     }
// `; 
export const CTAButton = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    height: 62px;
    width: 320px;
    background-color: #ff4438;
    border-radius: 6px;
    box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.5);
    color: #f1efef;
    font-size: 24px;
    margin-bottom: 118px;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        height: 43px;
        width: 222px;
        border-radius: 4px;
        box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.5);
        font-size: 16px;
        margin-bottom: 69px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        width: 320px;
        margin-bottom: 48px;
    }
    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        width: 284px;
    }
`;

export const SliderContainer = styled.div`
    width: 100%;

    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        width: 150%;
        margin-right: calc(-50% + 108.5px);
    }
`