import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoSVG } from '../../../icons/Logo.svg';
import { ReactComponent as LogoLeanSVG } from '../../../icons/LogoLean.svg';
import { ReactComponent as LogoLightSVG } from '../../../icons/LogoLight.svg';
import { ReactComponent as LoginSVG } from '../../../icons/Login.svg';
import { ReactComponent as ArrowSVG } from '../../../icons/Arrow.svg'
import { ReactComponent as ArrowDownSVG } from '../../../icons/ArrowDown.svg'
import { ReactComponent as ArrowRedSVG } from '../../../icons/ArrowRed.svg'
import { ReactComponent as LogoutSVG } from '../../../icons/Logout.svg'
import { ReactComponent as BotLightSVG } from '../../../icons/BotLight.svg'
import { ReactComponent as SoonSVG } from '../../../icons/Soon.svg'

import { DESKTOP_WIDTH, TABLET_WIDTH, MOBILE_WIDTH, MOBILE_S_WIDTH, ANIMATION_SPEED } from '../../globalUtils'

export const NavHolder = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
`;

export const Nav = styled.nav`
    display: flex;
    flex: 1 0 auto;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: ${DESKTOP_WIDTH}px;
    height: ${({ inPersonalZone }) => inPersonalZone ? 80 : 103}px;
    flex: 0 0 auto;
    align-self: center;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        width: ${TABLET_WIDTH}px;
        height: 96px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        width: ${MOBILE_WIDTH}px;
        height: 52px;
    }
    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        width: ${MOBILE_S_WIDTH}px;
        height: 46px;
    }
`;

export const Logo = styled(LogoSVG)`
    height: 50px;
    width: 129px;
    
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        display: none;
    }
`;

export const LogoLean = styled(LogoLeanSVG)`
    display: none;
    height: 24px;
    width: 92px;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        display: inherit;
    }
`;

export const Links = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 477px;
    
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        display: none;
    }
`;

export const StyledLinkHref = styled.a`
    text-decoration: none;
    color: #${({ current }) => current === 'true' ? 'ff4438' : '003a70'};
    font-size: 22px;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        position: relative;
        color: #ffffff;
        font-size: 18px;
        margin-bottom: 27px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;

export const StyledLink = styled(Link)`
    text-decoration: none;
    color: #${({ current }) => current === 'true' ? 'ff4438' : '003a70'};
    font-size: 22px;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        position: relative;
        color: #ffffff;
        font-size: 18px;
        margin-bottom: 27px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;

export const RedStripe = styled.div`
    display: none;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        display: block;
        position: absolute;
        height: 3px;
        width: 8px;
        background-color: #ff4438;
        top: 0;
        right: 0;
    }
`;

export const Arrow = styled(ArrowSVG)`
    display: none;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        display: block;
        height: 11px;
        width: 6px;
        fill: #ff4438;
        margin-left: 6px;
    }
`;

export const LoginDesktop = styled(Link)`
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: #ff4438;
    line-height: 1.05;
    font-size: 22px; 
    font-weight: 600;
    
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        display: none;
    }
`;

export const LoginNonDesktop = styled(Link)`
    display: none;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #ff4438;
    font-size: 12px;
    
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        display: flex;
    }
`;

export const LoginIcon = styled(LoginSVG)`
    height: 22px;
    width: 22px;
    margin-left: 4px;
    fill: #003A70;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        margin: 0 0 0 2px;
    }
`;

export const Hamburger = styled.div`
    display: none;
    position: relative;
    height: 21px;
    width: 26px;
    cursor: pointer;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        display: ${({ inPersonalZone }) => inPersonalZone ? 'none' : 'block'};
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        display: block;
    }
`;

export const HamburgerIndication = styled.span`
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    margin-top: -1px;
    background-color: ${({ navOpen }) => navOpen ? 'transparent' : '#ff4438'};
    user-select: none;
    transition: ${ANIMATION_SPEED}ms background-color ease-in-out;
    border-radius: 1px;

    ::after, 
    ::before {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        background: #ff4438;
        border-radius: 1px;
        content: '';       
        transition: ${ANIMATION_SPEED}ms transform ease-in-out;
    }

    ::after {
        transform: ${({ navOpen }) => navOpen ?
        'translateX(-1px) rotate(-45deg) translateY(7px) scaleX(0.6)' :
        'translateY(7px)'};
    }

    ::before {
        transform: ${({ navOpen }) => navOpen ?
        'translateX(-1px) rotate(45deg) translateY(-7px) scaleX(0.6)' :
        'translateY(-7px)'};
    }
`;

export const SideNavigator = styled.div`
    height: 100%;
    width: ${({ navOpen }) => navOpen ? 300 : 0}px;
    transition: ${ANIMATION_SPEED}ms width ease-in-out;
    background-image: linear-gradient(#425c8a 0%, #003a70 100%);
    display: none;
    flex-direction: column;
    align-items: center;
    z-index: 0;
    flex: 1 0 auto;
    overflow-x: hidden;
    overflow-y: auto;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        display: flex;
    }
`;

export const SideNavigatorLinks = styled.div`
    display: flex;
    flex-direction: column;
    padding: 57px 22px 0;
    width: 256px;
`;

export const LogoLight = styled(LogoLightSVG)`
    height: 30px;
    width: 77px;
    margin: 19px 0 25px;
`;

export const LoginMobile = styled(Link)`
    color: #ffffff;
    line-height: 1.28;
    font-size: 18px;
    display: flex;
    margin-bottom: 51px;
    align-items: center;
    text-decoration: none;
`;

export const LoginIconMobile = styled(LoginSVG)`
    fill: #ffffff;
    margin-left: 9px;
    height: 18px;
`;

// export const ChatBot = styled(Link)`
export const ChatBot = styled.a`
    text-decoration: none;
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    display: flex;
`;

export const BotLight = styled(BotLightSVG)`
    height: 25px;
    width: 25px;
`;

export const PersonalLinksDesktop = styled.div`
    display: flex;
    height: 100%;
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        display: none;
    }
`;

export const PersonalLinksTablet = styled.div`
    display: none;
    height: 100%;
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        display: flex;
        align-self: center;
        height: 71px;
        width: ${TABLET_WIDTH}px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        height: 56px;
        width: ${MOBILE_WIDTH}px;
    }
    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        width: ${MOBILE_S_WIDTH}px;
    }
`;

export const PersonalLinks = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
`;

export const PersonalLink = styled(Link)`
    position: relative;
    cursor: ${({ soon }) => soon ? 'default' : 'pointer'};
    display: flex;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 20px;
    width: 70px;
    height: 100%;
    color: ${({ current }) => current === 'true' ? '#ff4438' : '#003a70'};
    font-size: 16px;
    border-right: 1px solid #e9ecf1;
    transition: ${ANIMATION_SPEED}ms all ease-in-out;
    box-shadow: inset 0px -${({ current }) => current === 'true' ? 6 : 0}px 0 0 #fe564b;
    :last-of-type {
        border-left: 1px solid #e9ecf1;
    }
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        padding: 0 36px;
        box-shadow: inset 0px -${({ current }) => current === 'true' ? 9 : 0}px 0 0 #1a4e7f;
        :first-of-type {
            border-right: 0;
        }
        :last-of-type {
            border-left: 0;
        }
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        font-size: 12px;
        padding: 0 11px;
        width: 42px;
        box-shadow: inset 0px -${({ current }) => current === 'true' ? 7 : 0}px 0 0 #1a4e7f;
    }
    @media only screen and (max-width: ${MOBILE_WIDTH}px) {
        font-size: 11px;
        padding: 0 8px;
        width: 42px;
        box-shadow: inset 0px -${({ current }) => current === 'true' ? 6 : 0}px 0 0 #1a4e7f;
    }
`;

export const PersonalDropDown = styled.div`
    position: relative;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #ff4438;
    line-height: 1.05;
    font-size: 22px;
    font-weight: 600;
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        display: none;
    }
`;

export const PersonalDropDownMobile = styled.div`
    display: none;
    color: #ff4438;
    font-size: 13px;
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        display: block;
    }
`;

export const ArrowDownIcon = styled(ArrowDownSVG)`
    width: 21px;
    height: 12px;
    margin: 3px 6px 0 0;
`;

export const DropDownMenu = styled.div`
    position: absolute;
    top: 80px;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 189px;
    background-color: rgba(255, 255, 255, 0.97);
    border-radius: 1px;
    box-shadow: 0 4px 7px 0px rgba(0, 0, 0, 0.5);
    z-index: 4;
`;

export const DDMenuAction = styled(Link)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    padding: 20px 15px 20px 17px;
    color: #003a70;
    font-size: 16px;
    font-weight: 400;
`;

export const ArrowRedIcon = styled(ArrowRedSVG)`
    height: 11px;
    width: 6px;
`;

export const LogoutIcon = styled(LogoutSVG)`
    height: 12px;
    width: 12px;
`;

export const SoonIcon = styled(SoonSVG)`
    position: absolute;
    top: 0;
    left: 0;
    height: 53px;
    width: 66px;
`;