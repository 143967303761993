import React, { useState } from 'react';
import styled from 'styled-components';

const CB = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 12px;
    width: 11px;
    border: 1px solid rgb(0, 58, 112);
    background-color: transparent;
    margin-left: 8px;
`;

const CBChecked = styled.div`
    height: 10px;
    width: 9px;
    background-color: rgb(0, 58, 112);
`;

const Checkbox = ({ initial = false, onChange }) => {
    const [checked, setChecked] = useState(initial);

    return <CB
        onClick={() => {
            const nextChecked = !checked;
            setChecked(nextChecked);
            !!onChange && onChange(nextChecked);
        }}
    >{!!checked && <CBChecked />}</CB>
};

export default Checkbox;