import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { CONTENT_BY_PAGE } from '../../../graphql';
import { reduceContent } from '../../globalUtils';
import {
    GrayContainer, Area, Title, RedStripe, Text, ContactContainer,
} from './index.style';
import ContactForm from './ContactForm';
import ContactWays from './ContactWays';

const Contact = ({forwardRef}) => {
    const { loading, error, data } = useQuery(CONTENT_BY_PAGE, {
        variables: {
            page: 'home',
            language: 'he',
        },
    });

    if (loading) {
        return 'loading';
    }
    if (error) {
        return 'error';
    }
    const { contentByPage } = data;
    const { letsTalk: { text, contactUs, contactUsWays } } = reduceContent(contentByPage)

    return <GrayContainer>
        <Area fixedWidth={true}>
            <Title>
                <RedStripe />
                בואו נדבר
            </Title>
            {!!text && <Text>{text.text}</Text>}
            <ContactContainer ref={forwardRef}>
                <ContactForm contactUs={contactUs} />
                <ContactWays contactUsWays={contactUsWays} />
            </ContactContainer>
        </Area>
    </GrayContainer>;
};

export default Contact;