import { setGlobal } from 'reactn';
import { cache } from './Apollo';

export const Init = () => {
    let _token = localStorage.getItem('token');
    let _user = localStorage.getItem('user');
    if (_token && _user) {
        const user = JSON.parse(_user);

        setGlobal({
            user,
            isLogged: true,
        });
    }
};

export const LoginUser = (user) => {
    localStorage.setItem('token', user.token);
    localStorage.setItem('user', JSON.stringify(user));

    setGlobal({
        user,
        isLogged: true,
    });
};

export const UpdateUser = (user) => {
    localStorage.setItem('user', JSON.stringify(user));

    setGlobal({
        user,
        isLogged: true,
    });
};

export const LogoutUser = () => {
    cache.reset();
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setGlobal({
        user: {},
        isLogged: false,
    });
};