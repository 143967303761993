import React from 'react';
import { QnAContainer, ArrowSolid, Question, Answer } from './index.style';

export const QnA = ({ i, question, answer, selected, setSelected }) => (
    <QnAContainer onClick={() => setSelected(selected ? -1 : i)}>
        <ArrowSolid selected={selected} />
        <Question selected={selected}>{question}</Question>
        <Answer selected={selected}>{answer}</Answer>
    </QnAContainer>
);
