import React from 'react';
import {
    TestimonyContainer, HeartIcon, TestimonyBG, Image, Name, Text
} from './Testimony.style';
const snap = navigator.userAgent !== 'ReactSnap';
const Testimony = ({ image, name, text, active }) => (<TestimonyContainer active={active}>
    <HeartIcon />
    <TestimonyBG />
    {!!snap && <Image src={image} alt={`Picture of ${name}`} />}
    <Name>{name}</Name>
    <Text>{text}</Text>
</TestimonyContainer>);

export default Testimony;