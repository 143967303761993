import { gql } from 'apollo-boost';

export const CONTENT_BY_PAGE = gql`
query ContentByPage($page: String!, $language: String!) {
    contentByPage(page: $page, language: $language) {
        id
        key
        ... on Text {
            text
        }
        ... on Number {
            number
        }
        ... on Image {
            image
        }
    }
}`;

export const CLIENT_TESTIMONIES = gql`
query ClientTestimonies {
    clientTestimonies {
        id
        image
        name
        text
    }
}`;

export const ARTICLES = gql`
query Articles {
    articles {
        id
        image
        url
        publisher
        text
    }
}`;

export const USE_CASES = gql`
query UseCases {
    useCases {
        id
        title
        text
    }
}`;


export const CATEGORY_FAQS = gql`
query CategoryFaqs($category: Category!) {
    categoryFaqs(category: $category) {
        id
        position
        question
        answer
    }
}`;

export const BOT_BY_NAME = gql`
query BotByName($name: String!) {
    botByName(name: $name) {
        id
        lead
        source
        innerName
        name
        code
        sourceName
        image
        content_0
        content_1
    }
}`;

export const REFRESH_USER = gql`
query RefreshUser {
    refreshUser {
        providerId
        name {
                first
                last
        }
        idNumber
        phone
        email
        status
        address
        taxEstimation
        lifeEventsIds
        accountManager {
                image
                name
                email
                phone
        }
        taxRefunds {
                date
                amount
        }
        insurances {
                company
                kind
                name
        }
        pansions {
                company
                kind
                amount
        }
    }
}`;

export const LIFE_EVENTS = gql`
query LifeEvents {
    lifeEvents {
        id
        position
        text
    }
}`;

export const QUESTIONNAIRE_BY_NAME = gql`
query QuestionnaireByName($name: String!) {
    questionnaireByName(name: $name) {
        id
        name
        manual
        buttonBack
        buttonNext
        steps {
            id
            position
            ... on Opening {
                title
                text
                buttonText
                fastText
                secureText
            }
            ... on Note {
                title
                icon
                buttonText
            }
            ... on Question {
                text
                refKey
                answers {
                    id
                    value
                    text
                }
                stepsGroups {
                    id
                    position
                    activeOn
                    steps {
                        id
                        position
                        ... on Opening {
                            title
                            text
                            buttonText
                            fastText
                            secureText
                        }
                        ... on Note {
                            title
                            icon
                            buttonText
                        }
                        ... on Question {
                            text
                            refKey
                            answers {
                                id
                                value
                                text
                            }
                        }
                    }
                }
            }
        }
    }
}`;